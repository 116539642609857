:root {
    --color-brand-primary: hsl(var(--color-brand-primary-h), 43%, 43%);
    --color-brand-primary-h: 251;
    --color-brand-primary-s: 43%;
    --color-brand-primary-l: 43%;
    --color-brand-accent: hsl(var(--color-brand-accent-h), 96%, 61%);
    --color-brand-accent-h: 231;
    --color-brand-accent-s: 71%;
    --color-brand-accent-l: 63%;
    --color-brand-accent-bg: hsl(calc(var(--color-brand-accent-h) + 17), 100%, 96%);
    --ratio: 1.4;
    --s-6: calc(var(--s-5) / var(--ratio));
    --s-5: calc(var(--s-4) / var(--ratio));
    --s-4: calc(var(--s-3) / var(--ratio));
    --s-3: calc(var(--s-2) / var(--ratio));
    --s-2: calc(var(--s-1) / var(--ratio));
    --s-1: calc(var(--s0) / var(--ratio));
    --s0: calc(1.05rem + 0.333vw);
    --s1: calc(var(--s0) * var(--ratio));
    --s2: calc(var(--s1) * var(--ratio));
    --s3: calc(var(--s2) * var(--ratio));
    --s4: calc(var(--s3) * var(--ratio));
    --s5: calc(var(--s4) * var(--ratio));
    --s6: calc(var(--s5) * var(--ratio));
}


.c-services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media (min-width: 40.625em) {
    .c-services {
        padding: 0 var(--s3);
    }
}

.c-services__item {
    flex: 45%;
    width: 45%;
    background: #fff;
    padding: calc(var(--s2) - 0.6rem) var(--s1);
    border-radius: 25px;
    box-shadow: 0 7px 20px rgba(100, 28, 2, 0.135);
    transition: all 300ms ease, transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: relative;
    z-index: 1;
    margin: 1em;
}

.c-services__item h3 {
    color: var(--color-brand-primary);
    font-size: var(--s1);
    letter-spacing: -0.04em;
    line-height: 1.2;
}

.c-services__item p {
    margin-top: var(--s-1);
    font-weight: 400;
    color: hsla(var(--color-brand-primary-h), var(--color-brand-primary-s), var(--color-brand-primary-l), 0.65);
}

.c-services__item:hover {
    background-color: var(--color-brand-accent);
}

@media (min-width: 40.625em) {
    .c-services__item:hover {
        transform: translateY(-10px);
    }
}

@media (min-width: 48em) {
    .c-services__item:hover {
        transform: translateY(-10px);
    }
}

.c-services__item:hover h3 {
    color: #fff;
}

.c-services__item:hover p {
    color: rgba(255, 255, 255, 1);
}

.c-services__item:hover:after {
    background-color: #fff;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmI2ZjNjIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGRhdGEtbmFtZT0iTGF5ZXIgMSIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHg9IjBweCIgeT0iMHB4Ij48dGl0bGU+NTI8L3RpdGxlPjxwYXRoIGQ9Ik04MS4zMDMyOSwzOC41MjkzOUExNC4wMTgsMTQuMDE4LDAsMSwwLDYxLjQ3NywxOC43MDY3MUw0Ny4wMDMxNSwzMy4xNzkxNGExNC4wMzAzNywxNC4wMzAzNywwLDAsMCwwLDE5LjgyMTcxLDQuODAxMTMsNC44MDExMywwLDAsMS02Ljc4OTc5LDYuNzg5ODcsMjMuNjQzMjcsMjMuNjQzMjcsMCwwLDEsMC0zMy40MDE0NUw1NC42ODcyMSwxMS45MTY4NEEyMy42MjAzLDIzLjYyMDMsMCwwLDEsODguMDkzMDgsNDUuMzE5MjdMODAuOTIzOCw1Mi40ODcxMWE0LjgwMTE0LDQuODAxMTQsMCwwLDEtNi43ODk4LTYuNzg5ODdaTTExLjkwNzQxLDg4LjA5MzlhMjMuNjUwNTMsMjMuNjUwNTMsMCwwLDAsMzMuNDA1ODYtLjAwMUw1OS43ODY2NCw3My42MjE0N2EyMy42MTU4MywyMy42MTU4MywwLDAsMCwwLTMzLjQwMTQ1LDQuODAxMTQsNC44MDExNCwwLDAsMC02Ljc4OTc5LDYuNzg5ODgsMTQuMDE1MzEsMTQuMDE1MzEsMCwwLDEsMCwxOS44MjI2OEwzOC41MjM0OCw4MS4zMDRBMTQuMDE4LDE0LjAxOCwwLDEsMSwxOC42OTcyLDYxLjQ4MTM1TDI1Ljg2Niw1NC4zMTM1YTQuODAxMTQsNC44MDExNCwwLDAsMC02Ljc4OTgtNi43ODk4N2wtNy4xNjg3OSw3LjE2Nzg1QTIzLjY0NDg5LDIzLjY0NDg5LDAsMCwwLDExLjkwNzQxLDg4LjA5MzlaIj48L3BhdGg+PC9zdmc+");
}
.cookie-message {
    z-index: 999999;
    border-radius: 10px;
    padding: 15px 0;
    background: #f7f8fb;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.05),
      0 10px 10px 2px rgba(0, 0, 0, 0.05);
    font-size: 12px;
    line-height: 40px;
    border-top: 1px solid #e4e4e4;
    position: fixed;
    z-index: 125;
    bottom: 10%;
    right: 2%;
    margin: auto;
    max-width: 540px;
    display: -ms-flexbox;
    display: flex;
  }
  .cookie-message img {
    height: 50px;
    width: 50px;
    margin: 0 15px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .cookie-message span {
    display: inline-block;
    line-height: 1.5;
    padding-right: 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .cookie-message span.close {
    z-index: 999999;
    cursor: pointer;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    border: none;
    font-size: 36px;
    padding: 0 20px 0 16px;
    position: relative;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    color: #919191;
    transition: color 0.2s;
  }
  .cookie-message a.close:hover {
    color: #795548;
  }
  .cookie-message a {
    display: inline-block;
    color: blue;
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }